
// title

.hero-title {
    font-size: 3rem;
    line-height: 1.3;
    font-weight: $font-weight-semibold;

    @media (max-width: 768px) {
        font-size: 2.6rem;
        line-height: 1.1;
    }
    @media (max-width: 576px) {
        font-size: 2rem !important;
    }
}

// hero-12
.hero-12 {
    height: 640px;

    .hero-image {
        position: relative;

        img {
            position: relative;
            z-index: 1;
        }

        &::before,
        &::after {
            content: "";
            background: url('/assets/images/pattern/dot3.svg');
            width: 8rem;
            height: 8rem;
            position: absolute;
            z-index: 0;
        }

        &::before {
            bottom: 2rem;
            @media (max-width: 768px) {
                top: 6rem;
            }
        }

        &::after {
            top: -2rem;
            right: 8rem;
        }
    }

    @media (max-width: 768px) {
        .hero-image {
            &::after {
                display: none;
            }
        }
    }
}

// hero-13
.hero-13 {
    .hero-right {
        .slider-container {
            position: relative;
    
            .slider-container-body {
                position: relative;
                padding: 0.5rem;
                z-index: 1;
            }
    
            &::after {
                content: "";
                background: url('/assets/images/pattern/dot3.svg');
                width: 8rem;
                height: 8rem;
                position: absolute;
                top: -3rem;
                right: 3rem;
                z-index: 0;
            }
        }
    
        .swiper-slide-content {
            position: relative;
    
            .video-overlay {
                background: rgba($white, 0.05);
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: 10;
            }
        }

        .img-container {
            position: relative;
            padding: 0.3rem;
            z-index: 1;
            background: $white;
            box-shadow: $box-shadow-lg;
            border-radius: $border-radius-lg;
            min-width: 160%;
        }
        &::before {
            content: "";
            background: url('/assets/images/pattern/dot2.svg');
            width: 6rem;
            height: 6rem;
            position: absolute;
            bottom: -2rem;
            left: -2rem;
            z-index: 0;
        }

        &::after {
            content: "";
            background: url('/assets/images/pattern/dot5.svg');
            width: 6rem;
            height: 6rem;
            position: absolute;
            top: -2.5rem;
            right: -6rem;
            z-index: 0;
            transform: rotate(45deg);
            z-index: 0;
        }
    }
}

@media (max-width: 1024px) {
    .hero-13 {
        padding-top: 4rem;
        .hero-right {
            .img-container {
                min-width: 60%;
            }
            &::after {
                right: 2rem;
            }
            .slider-container {
                display: flex;
                justify-content: center;
                &::after {
                    width: 6rem;
                    height: 6rem;
                }
                .slider {
                    width: 60%;
                }
            }
        }
    }
}
